<template>
  <v-navigation-drawer
    :value="showMainMenu"
    absolute
    temporary
    style="padding-top: 64px; z-index: 4;"
    @input="!$event && setShowMainMenu(false)"
    >
    <v-list class="py-0">
      <v-list-item v-for="(item, i) in modules" class="py-0" :key="'main-menu' + item.description" @click="selectModule(item)">
        <v-list-item-title>{{ item.description }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="modules.length == 0">
        <v-btn color="info" block outlined :to="orgSelected ? '/organization/module/index' : '/user/module/index'">Выбрать модули</v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  data: () => ({
  }),
  computed: {
    ...mapState(['showMainMenu']),
    ...mapState('organization', { orgSelected: 'selected' }),
    ...mapGetters({ modules: 'getModules' }),
  },
  methods: {
    ...mapMutations(['setShowMainMenu']),
    ...mapActions(['selectModule'])
    // go(route) {
    //   if (this.$route.path == route) {
    //     this.setShowMainMenu(false)
    //     return
    //   }
    //   this.setShowMainMenu(false)
    //   this.$router.push(route)
    // }
  }
}
</script>
