<template>
    <v-navigation-drawer
      app
      clipped
      permanent
      color="blue lighten-5"
      :mini-variant.sync="mini"
      style="z-index: 1;"
     >
      <v-list nav>
        <template v-for="item in items">
          <v-list-group
            v-if="item.children"
            :key="item.name"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="subItem in item.children"
              :key="subItem.name"
              :to="subItem.route"
              class="px-2"
              >
              <v-list-item-icon><v-icon v-text="subItem.icon"></v-icon></v-list-item-icon>
              <v-list-item-title v-text="subItem.name"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.name" :to="item.route">
            <v-list-item-icon><v-icon v-text="item.icon"></v-icon></v-list-item-icon>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <div :class="[{ 'text-center': mini, 'text-right': !mini }, 'pa-3']">
          <v-icon v-if="mini" large @click="mini = false">mdi-chevron-right</v-icon>
          <v-icon v-else large @click="mini = true">mdi-chevron-left</v-icon>
        </div>
      </template>
    </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    mini: false,
  })
}
</script>
