<template>
  <v-app-bar color="primary" app clipped-left dark>
    <v-app-bar-nav-icon tile @click="setShowMainMenu(true)"/>
    <v-toolbar-title>{{activeModule && activeModule.description}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <Avatar @click.native="setShowAvatarMenu(true)"/>
  </v-app-bar>
</template>

<script>
import Avatar from './Avatar.vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  components: { Avatar },
  computed: {
    ...mapState('organization', { orgSelected: 'selected' }),
    ...mapGetters({ activeModule: 'getActiveModule' }),
  },
  methods: {
    ...mapMutations(['setShowMainMenu', 'setShowAvatarMenu']),
    ...mapActions('user', ['logout']),
  }
}
</script>
